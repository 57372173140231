import request from '@/utils/request'
const baseURL = '/baseapi'
const SpecialApi = {
  SpecialList: '/admin/special/specialList',
  SpecialRecordList: '/admin/special/list',
  SpecialInfo: '/admin/special/specialInfo',
  SpecialById: '/admin/special/specialById',
  SaveSpecialRecord: '/admin/special/saveSpecialRecord',
  DelSpecialRecord: '/admin/special/delSpecialRecord',
  SpecialCommonList: '/admin/special/commonlist',
  ResidentCode: '/admin/special/residentCode',
  SearchSpecialUser: '/admin/special/searchSpecialUser',
  SpeciaCondition: '/admin/special/condition'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function specialList (parameter) {
  return request({
    baseURL: baseURL,
    url: SpecialApi.SpecialList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function speciaCondition () {
  return request({
    baseURL: baseURL,
    url: SpecialApi.SpeciaCondition,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function specialRecordList (parameter) {
  return request({
    baseURL: baseURL,
    url: SpecialApi.SpecialRecordList,
    method: 'post',
    data: parameter
  })
}

export function specialCommonList (parameter) {
  return request({
    baseURL: baseURL,
    url: SpecialApi.SpecialCommonList,
    method: 'post',
    data: parameter
  })
}

export function specialInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: SpecialApi.SpecialInfo,
    method: 'post',
    data: parameter
  })
}

export function specialById (parameter) {
  return request({
    baseURL: baseURL,
    url: SpecialApi.SpecialById,
    method: 'post',
    data: parameter
  })
}

export function saveSpecialRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: SpecialApi.SaveSpecialRecord,
    method: 'post',
    data: parameter
  })
}

export function delSpecialRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: SpecialApi.DelSpecialRecord,
    method: 'post',
    data: parameter
  })
}

export function residentCode (parameter) {
  return request({
    baseURL: baseURL,
    url: SpecialApi.ResidentCode,
    method: 'post',
    data: parameter
  })
}

export function searchSpecialUser (parameter) {
  return request({
    baseURL: baseURL,
    url: SpecialApi.SearchSpecialUser,
    method: 'post',
    data: parameter
  })
}
