<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>特殊人群信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="用户名称">
                <div style="width: 500px;">
                  {{ formData.realName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="电话号码">
                <div style="width: 500px;">
                  {{ formData.telephone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="住址">
                <div style="width: 500px;">
                  {{ formData.address }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="性别">
                <div style="width: 500px;" v-if="formData.gender === 0">
                  未知
                </div>
                <div style="width: 500px;" v-else-if="formData.gender === 1">
                  男
                </div>
                <div style="width: 500px;" v-else>
                  女
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="类型">
                <div style="width: 500px;">
                  <a-tag color="cyan">{{ formData.typeName }}</a-tag>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="录入时间">
                <div style="width: 500px;">
                  {{ formData.acreateTime }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="住户身份二维码" v-if="codeUrl !== ''">
                <div style="width: 500px;">
                  <span>
                    <img :src="codeUrl" />
                  </span>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { residentCode } from '@/api/special'
export default {
  name: 'SeeCommonspecial',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {},
      codeUrl: ''
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    specialInfo (data) {
      const _this = this
      _this.seeInfoVisible = true
      _this.formData = data
      _this.residentCode(_this.formData.id)
    },
    residentCode (residentId) {
      const _this = this
      _this.loading = true
      residentCode({ residentId: residentId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.codeUrl = res.result.codeUrl
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
